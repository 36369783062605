<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Списание</div>
                <div class="page__desc">Укажите количество продукта и причину списания</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="supply !== null" class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="store_id">Магазин</label>
                <div class="control-group">
                    <select
                            v-model="supply.store_id"
                            id="store_id"
                            class="form-control"
                            required
                            disabled>
                        <option
                                :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="product_id">Продукт</label>
                <div class="control-group">
                    <select
                            v-model="supply.product_id"
                            id="product_id"
                            class="form-control"
                            required
                            disabled>

                        <option
                                :value="product.id">{{ product.title }}</option>
                    </select>
                </div>
                <br>

                <!--<label for="expired_at">Срок годности</label>-->
                <!--<div class="control-group">-->
                    <!--<input-->
                            <!--v-model="expired_at"-->
                            <!--id="expired_at"-->
                            <!--type="datetime-local"-->
                            <!--class="form-control"-->
                            <!--required-->
                            <!--disabled>-->
                <!--</div>-->
                <!--<br>-->

                <label for="quantity">Списано</label>
                <div class="row">
                    <div class="col-sm-2">
                        <div class="control-group">
                            <div class="input-group">
                                <input
                                        v-model="quantity"
                                        :max="supply.quantity_actual"
                                        :placeholder="1"
                                        id="quantity"
                                        type="number"
                                        class="form-control">
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-light" disabled> / </button>
                    <div class="col-sm-2">
                        <div class="control-group">
                            <div class="input-group">
                                <input
                                        v-model="supply.quantity_actual"
                                        type="number"
                                        class="form-control"
                                        disabled>
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <label for="cause">Причина</label>
                <div class="control-group">
                    <select
                            v-model="cause"
                            id="cause"
                            class="form-control"
                            required>

                        <option value="expire">Срок годности</option>
                        <option value="defect">Дефект</option>
                        <option value="other">Другое</option>
                    </select>
                </div>
                <br>

                <label for="comment">Комментарий</label>
                <div class="control-group">
                    <textarea
                            v-model="comment"
                            id="comment"
                            class="form-control"></textarea>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreWriteOffsCreate',
        data() {
            return {
                quantity: null,
                comment: null,
                cause: null,
            }
        },
        computed: {
            supply() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.supply : null
            },
            expired_at: {
                get() {
                    return this.$store.state.supplies.supply != null ? this.$moment(this.$store.state.supplies.supply.supply.expired_at * 1000).format('YYYY-MM-DDTHH:mm') : null
                },
                set(newVal) {
                    this.$store.state.supplies.supply.supply.expired_at = this.$moment(newVal).unix()
                }
            },
            store() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.store : null
            },
            product() {
                return this.$store.state.supplies.supply != null ? this.$store.state.supplies.supply.product : null
            },
        },
        methods: {
            ...mapActions([
                'storeSuppliesGet',
                'storeWriteOffsCreate',
            ]),
            async getSupply() {
                await this.storeSuppliesGet({
                    id: this.$route.query.supply_id,
                })
            },
            async create() {
                await this.storeWriteOffsCreate({
                    supply_id: this.$route.query.supply_id,
                    quantity: this.quantity,
                    cause: this.cause,
                    comment: this.comment
                })
            }
        },
        created() {
            this.getSupply();
        }
    }
</script>